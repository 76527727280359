<template>
    <div>
        <span v-if="'V_SCRL' === reward.rewardType" class="text-capitalize">
            ${{ reward.amount }}
        </span>
        <span v-if="'LAND' === reward.rewardType" class="text-capitalize d-flex align-center">
            {{ reward.amount }}x
            <img :src="'/img/leaderboard/rewards/'+reward.rarity+'_100.png'" class="reward-img mr-1"
                 alt="land"/>
            {{ reward.rarity }} land
        </span>
        <span v-if="'ENHANCEMENT_BOX' === reward.rewardType" class="text-capitalize d-flex align-center">
            {{ reward.amount }}x
            <img src="/img/leaderboard/rewards//bottomless-bag_100.png" class="reward-img ml-1 mr-1" alt="bag"/>
            Enhancements box
        </span>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        reward: Object,
    },
    data() {
        return {}
    },
    async mounted() {

    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
img {
    width: 20px;
}

@media only screen and (max-width: $md) {
    .reward-img {
        display: none;
    }
}
</style>
