var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    "V_SCRL" === _vm.reward.rewardType
      ? _c("span", { staticClass: "text-capitalize" }, [
          _vm._v(" $" + _vm._s(_vm.reward.amount) + " "),
        ])
      : _vm._e(),
    "LAND" === _vm.reward.rewardType
      ? _c("span", { staticClass: "text-capitalize d-flex align-center" }, [
          _vm._v(" " + _vm._s(_vm.reward.amount) + "x "),
          _c("img", {
            staticClass: "reward-img mr-1",
            attrs: {
              src: "/img/leaderboard/rewards/" + _vm.reward.rarity + "_100.png",
              alt: "land",
            },
          }),
          _vm._v(" " + _vm._s(_vm.reward.rarity) + " land "),
        ])
      : _vm._e(),
    "ENHANCEMENT_BOX" === _vm.reward.rewardType
      ? _c("span", { staticClass: "text-capitalize d-flex align-center" }, [
          _vm._v(" " + _vm._s(_vm.reward.amount) + "x "),
          _c("img", {
            staticClass: "reward-img ml-1 mr-1",
            attrs: {
              src: "/img/leaderboard/rewards//bottomless-bag_100.png",
              alt: "bag",
            },
          }),
          _vm._v(" Enhancements box "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }